<template>
  <div class="account">
    <PageSubHeader :pageSubHeader="pageSubHeader" />

    <main>
      <section id="account_search_extend" class="account_search">
        <div class="w1000">

          <!-- 表單主標題 -->
          <div class="main_title_box">
            <h3 class="main_title txt-bold">展延申請查詢</h3>
            <div class="img_box">
              <img src="@/statics/img/index/icon_title2.svg" alt="">
            </div>
          </div>

          <!-- form area -->
          <div class="form_box">
            <form id="form_account_search_extend" action="" method="post">

              <div class="form_description">
                <p>
                  <span id="user_name">{{ user.name }}</span> 您好，<br><br>
                  <span class="txt-bold">
                    您的展延申請狀態為：
                    <span class="txt-red">{{ searchStatus }}</span>
                  </span><br>
                  <span v-show="searchStatus === '未通過'">備註：缺少VPN收案人數證明，請再次上傳資料。</span>
                </p>
              </div>
              <div class="form_interactive">
                <Upload
                  class="w100"
                  :class="{ disabled: searchStatus === '審核中' }"
                  v-model="user.upload.file"
                  :item="inputFormat.attachmentUpload"
                  :rules="[
                    val => !!val || '請上傳檔案!',
                  ]"
                />
              </div>
              <div class="form_submit txt-center">
                <button
                  type="button"
                  class="btn"
                  :class="{ disabled: searchStatus === '審核中' }"
                  @click="onSubmit"
                >送出</button>
                <!-- <router-link to="/application/payment" class="btn">
                  送出
                </router-link> -->
              </div>

            </form>
          </div>

        </div>
      </section>
    </main>
  </div>
</template>

<script>
import PageSubHeader from '@/components/PageSubHeader.vue';
import { AccountSearchSubHeader } from '@/lib/const';
import Upload from '@/components/form/Upload.vue';
import axios from 'axios';

export default {
  name: 'AccountSearchProfession',
  data() {
    return {
      ...AccountSearchSubHeader,
      searchStatus: '未通過',
      user: {
        name: '王小明',
        upload: {
          file: '',
        },
      },
      inputFormat: {
        attachmentUpload: {
          label: '附檔上傳',
          reminder: '格式: zip、jpg、png、pdf',
          accept: '.zip,.jpg,.png,.pdf',
          required: true,
        },
      },
    };
  },
  methods: {
    onSubmit() {
      const errorList = [];
      this.$children.forEach((item) => {
        if (item.$options.name && item.$options.name.match(/^Form/i)) {
          item.test();
          errorList.push(item.error);
        }
      });
      if (errorList.indexOf(true) === -1) {
        axios.post('https://my-json-server.typicode.com/typicode/demo/posts', this.user)
          // eslint-disable-next-line no-unused-vars
          .then((res) => {
            // console.log('success', res.data);
            // alert('附檔已送出，請等待審核結果！');
          });
        // .catch((error) => {
        //   console.log(error);
        // });
      } else {
        // console.log('必填欄位尚未完成！');
      }
    },
  },
  components: {
    PageSubHeader,
    Upload,
  },
};
</script>
